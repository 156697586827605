import * as React from "react"
import Seo from "../components/seo";
import Layout from "../components/layout";
import {CheckCircleIcon, MailIcon, PencilIcon, TrendingUpIcon, UsersIcon, ViewGridIcon} from "@heroicons/react/outline";
import {StaticImage} from "gatsby-plugin-image";
import WaveBottom from "../components/wave-bottom";
import WaveTop from "../components/wave-top";


const WhyEvitatPage = () => (
    <Layout>
        <Seo title="Why"/>
        <div className="overflow-hidden lg:relative">
            <div className="pt-4 sm:pt-4 lg:py-4 bg-gray-50">
                <div className="max-w-7xl mx-auto">
                    <div className="px-4 sm:px-12 sm:text-center max-w-4xl mx-auto lg:items-center">
                        <div>
                            <div>
                                <a href="#" className="inline-flex no-underline space-x-4">
                                <span
                                    className="rounded bg-orange-50 px-2.5 py-1 text-xs font-semibold text-orange-500 tracking-wide">
                                  Empowering renovator
                                </span>
                                </a>
                            </div>
                            <h1 className="mt-4 text-4xl tracking-tight font-extrabold text-gray-900 sm:mt-5 sm:leading-none lg:mt-6 lg:text-5xl xl:text-6xl">
                                <span className="block">Let's make your  home</span>
                                <span className="block">feel... Like home, with <span
                                    className="text-green-500">Evitat</span>.</span>
                                <span className="block">Why choose us? <span
                                    className="text-green-500">Easy</span>...</span>
                            </h1>
                        </div>
                    </div>
                    <dl className="space-y-10 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-8">

                        <div>
                            <dd className="mt-2 flex text-xl text-gray-500">
                                <CheckCircleIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400"
                                                 aria-hidden="true"/>
                                Convenient one-stop shop for conscious renovators
                            </dd>
                        </div>

                        <div>
                            <dd className="mt-2 flex text-xl text-gray-500">
                                <CheckCircleIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400"
                                                 aria-hidden="true"/>
                                Simple choice engine powered by AI technology
                            </dd>
                        </div>

                        <div>
                            <dd className="mt-2 flex text-xl text-gray-500">
                                <CheckCircleIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400"
                                                 aria-hidden="true"/>
                                Enabling deeper conversations and relationships between all players in the ecosystem.
                            </dd>
                        </div>

                    </dl>
                </div>
            </div>
            <WaveBottom/>
        </div>
        <div className="overflow-hidden lg:relative">
            <div className="pt-4 sm:pt-4 lg:py-4 bg-white">
                <div className="max-w-7xl mx-auto">
                    <div className="px-4 sm:px-12 sm:text-center max-w-5xl mx-auto lg:items-center">
                        <div>
                            <div>
                                <a href="#" className="inline-flex no-underline space-x-4">
                                <span
                                    className="rounded bg-green-50 px-2.5 py-1 text-xs font-semibold text-green-500 tracking-wide">
                                  Health & Climate Impact
                                </span>
                                </a>
                            </div>
                            <h1 className="mt-4 text-4xl tracking-tight font-extrabold text-gray-900 sm:mt-5 sm:leading-none lg:mt-6 lg:text-5xl xl:text-6xl">
                                Why now?
                            </h1>
                            <p className="text-xl text-gray-500 max-w-full">Making healthy sustainable home renovation easy and impactful for you and the planet is important, yet  20 percent of Australia's carbon footprint still comes from household emissions</p>
                            <p className="text-xl text-gray-500 max-w-full">In the age of climate change, Australians talk about finding a home that is comfortable, is a healthy place for their family and is affordable – all things that a sustainable home can deliver. </p>
                            <p className="text-xl text-gray-500 max-w-full">We are the connection point for the ecosystem around the renovation experience for a diverse range of reasons</p>
                        </div>
                    </div>
                    <div className="mt-12">
                        <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
                            <div className="pt-6">
                                <div className="flow-root text-center rounded-lg px-6 pb-8">
                                    <div className="-mt-6">
                                        <div className="text-center">
                                            <span
                                                className="inline-flex items-center justify-center rounded-md bg-green-500 p-3 shadow-lg">
                                              <MailIcon className="flex-shrink-0 h-5 w-5 text-white"
                                                              aria-hidden="true"/>
                                            </span>
                                        </div>
                                        <h3 className="mt-8 text-2xl font-semibold tracking-tight text-gray-900">Conscious
                                            Renovators</h3>
                                        <p className="mt-5 text-base text-gray-500">Conscious Renovators are dedicated
                                            to transforming their homes in sustainable and environmentally friendly
                                            ways</p>
                                    </div>
                                </div>
                            </div>
                            <div className="pt-6">
                                <div className="flow-root text-center rounded-lg px-6 pb-8">
                                    <div className="-mt-6">
                                        <div>
                                            <span
                                                className="inline-flex items-center justify-center rounded-md bg-green-500 p-3 shadow-lg">
                                                <TrendingUpIcon className="flex-shrink-0 h-5 w-5 text-white"
                                                                 aria-hidden="true"/>
                                            </span>
                                        </div>
                                        <h3 className="mt-8 text-2xl font-semibold tracking-tight text-gray-900">Suppliers
                                            & Pros</h3>
                                        <p className="mt-5 text-base text-gray-500">Suppliers & Professionals continue
                                            to be environmentally conscious and socially responsible in their business
                                            practices</p>
                                    </div>
                                </div>
                            </div>
                            <div className="pt-6">
                                <div className="flow-root text-center rounded-lg px-6 pb-8">
                                    <div className="-mt-6">
                                        <div>
                                            <span
                                                className="inline-flex items-center justify-center rounded-md bg-green-500 p-3 shadow-lg">
                                              <UsersIcon className="flex-shrink-0 h-5 w-5 text-white"
                                                              aria-hidden="true"/>
                                            </span>
                                        </div>
                                        <h3 className="mt-8 text-2xl font-semibold tracking-tight text-gray-900">Homeowners</h3>
                                        <p className="mt-5 text-base text-gray-500">Homeowners continue to shift towards
                                            sustainable-oriented residential properties</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-12">
                        <div className="grid grid-cols-1 gap-8 sm:grid-cols-1 lg:grid-cols-2">
                            <div className="pt-6">
                                <div className="flow-root md:float-right sm:float-none max-w-sm text-center rounded-lg bg-white px-6 pb-8">
                                    <div className="-mt-6">
                                        <div className="text-center">
                                            <span
                                                className="inline-flex items-center justify-center rounded-md bg-green-500 p-3 shadow-lg">
                                              <PencilIcon className="flex-shrink-0 h-5 w-5 text-white"
                                                        aria-hidden="true"/>
                                            </span>
                                        </div>
                                        <h3 className="mt-8 text-2xl font-semibold tracking-tight text-gray-900">Regulations</h3>
                                        <p className="mt-5 text-base text-gray-500">Climate regulations will impact the way you renovate</p>
                                    </div>
                                </div>
                            </div>
                            <div className="pt-6">
                                <div className="flow-root max-w-sm text-center rounded-lg bg-gray-white px-6 pb-8">
                                    <div className="-mt-6">
                                        <div>
                                            <span
                                                className="inline-flex items-center justify-center rounded-md bg-green-500 p-3 shadow-lg">
                                                <ViewGridIcon className="flex-shrink-0 h-5 w-5 text-white"
                                                                aria-hidden="true"/>
                                            </span>
                                        </div>
                                        <h3 className="mt-8 text-2xl font-semibold tracking-tight text-gray-900">Finance & Insurance</h3>
                                        <p className="mt-5 text-base text-gray-500">Finance & Insurance are shifting towards a more holistic approach that takes into account the impact it has on people, communities and the planet</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="overflow-hidden lg:relative">
            <div className="pt-4 sm:pt-4 lg:py-4 bg-white">
                <div className="max-w-7xl mx-auto">
                    <div className="pt-10 px-4 sm:px-12 sm:text-center lg:items-center  bg-[url('../images/square-bg.png')]">
                        <div className="max-w-5xl mx-auto ">
                            <div>
                                <a href="#" className="inline-flex no-underline space-x-4">
                                <span
                                    className="rounded bg-green-50 px-2.5 py-1 text-xs font-semibold text-green-500 tracking-wide">
                                  Status quo
                                </span>
                                </a>
                            </div>
                            <h1 className="mt-4 text-4xl tracking-tight font-extrabold text-gray-900 sm:mt-5 sm:leading-none lg:mt-6 lg:text-4xl xl:text-4xl">
                                Sustainable renovations are time-consuming, complex and static.
                            </h1>
                            <p className="text-xl text-gray-500 max-w-full">Around 80% of renovator prioritise sustainable home improvements in an early stage, however these goals are often 'crowded out' by competing decisions along the renovation journey </p>
                        </div>
                        <div className="mt-12">
                            <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
                                <div className="pt-6">
                                    <div className="flow-root text-center rounded-lg px-6 pb-8">
                                        <div className="">
                                            <h3 className="mt-8 text-2xl font-semibold tracking-tight text-gray-900">Perceived Cost Barrier</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="pt-6">
                                    <div className="flow-root text-center rounded-lg px-6 pb-8">
                                        <div className="">
                                            <h3 className="mt-8 text-2xl font-semibold tracking-tight text-gray-900">Decision Fatigue</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="pt-6">
                                    <div className="flow-root text-center rounded-lg px-6 pb-8">
                                        <div className="">
                                            <h3 className="mt-8 text-2xl font-semibold tracking-tight text-gray-900">Static Reports</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mt-12">
                            <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
                                <div className="">
                                    <div className="flow-root rounded-lg px-6 pb-8">
                                        <div className="">
                                            <h3 className="mt-8 text-2xl lg:text-left font-semibold tracking-tight text-gray-900">No Real Time Analysis</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="">
                                    <div className="flow-root rounded-lg px-6 pb-8">
                                        <div className="">
                                            <h3 className="mt-8 text-2xl text-left font-semibold tracking-tight text-gray-900">High Cost</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="">
                                    <div className="flow-root rounded-lg px-6 pb-8">
                                        <div className="">
                                            <h3 className="mt-8 text-2xl lg:text-left font-semibold tracking-tight text-gray-900">Static Reports</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>
        <div className="overflow-hidden lg:relative">
            <WaveTop/>
            <div className="pt-4 sm:pt-4 lg:py-4 bg-gray-50">
                <div className="lg:grid lg:grid-cols-12 lg:gap-8 max-w-7xl mx-auto">
                    <div className="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-5 lg:text-left">
                        <div>
                            <StaticImage
                                src="../images/community-image-homepage.png"
                                loading="eager"
                                width={615}
                                quality={95}
                                formats={["auto", "webp", "avif"]}
                                alt=""
                                style={{marginBottom: `var(--space-3)`}}
                            />
                        </div>
                    </div>
                    <div className="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-7 lg:flex lg:items-center">
                        <div className="">
                            <div className="ml-10 mb-10 mr-10">
                                <a href="#" className="no-underline inline-flex space-x-4">
                                <span
                                    className="rounded bg-green-100 px-2.5 py-1 text-xs font-semibold text-green-500 tracking-wide">
                                  Community
                                </span>
                                </a>
                            </div>
                            <div className="m-10">
                                <h1 className="text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">Ready to
                                    join the community of conscious renovators, suppliers and professionals?</h1>
                                <p className="mt-5 max-w-prose text-xl text-gray-500">Join our community today and become
                                    part of the shift in renovation.</p>
                                <button
                                    type="button"
                                    className="flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-xl font-medium text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-400"
                                >
                                    Join today
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
)

export default WhyEvitatPage
